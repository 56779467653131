<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-07 15:58:47
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-03 09:28:20
-->
<template>
  <div class="business_card">
    <a-card :bordered="false">
      <div v-if="handle === 'detail'">
        <a-form-model layout="horizontal" :label-col="{ span: 2 }" :wrapper-col="{ span: 21 }">
          <a-form-model-item label="公司名称">{{ detail.companyName }}</a-form-model-item>
          <a-form-model-item label="公司地址">{{ detail.companyAddress }}</a-form-model-item>
          <a-form-model-item label="姓名">{{ detail.name }}</a-form-model-item>
          <!-- <a-form-model-item label="职位">{{ detail.positionId }}</a-form-model-item> -->
          <a-form-model-item label="手机号">{{ detail.mobile }}</a-form-model-item>
          <a-form-model-item label="邮箱">{{ detail.email }}</a-form-model-item>
          <div class="footer-btns">
            <a-button v-if="isEdit" type="default" @click="onEdit">修改</a-button>
          </div>
        </a-form-model>
      </div>
      <div v-else>
        <a-form-model
          ref="form"
          :model="detail"
          :rules="formRule"
          layout="horizontal"
          :label-col="{ span: 2 }"
          :wrapper-col="{ span: 6 }"
        >
          <a-form-model-item label="公司名称">
            <a-input v-model="detail.companyName" disabled allowClear></a-input>
          </a-form-model-item>
          <a-form-model-item label="公司地址">{{ detail.companyAddress }}</a-form-model-item>
          <a-form-model-item label="姓名" prop="name">
            <a-input v-model="detail.name" allowClear></a-input>
          </a-form-model-item>
          <!-- <a-form-model-item label="职位" prop="positionId">
            <a-select placeholder="请选择" v-model="detail.positionId">
              <a-select-option :value="item.id" v-for="(item, index) in positionList" :key="index">{{
                item.name
              }}</a-select-option>
            </a-select>
          </a-form-model-item> -->
          <a-form-model-item label="手机号" prop="mobile">
            <a-input v-model="detail.mobile" allowClear></a-input>
          </a-form-model-item>
          <a-form-model-item label="邮箱" prop="email">
            <a-input v-model="detail.email" allowClear></a-input>
          </a-form-model-item>
          <div class="footer-btns">
            <a-button type="default" @click="onSubmit">保存</a-button>
            <a-button type="default" @click="onHome">返回</a-button>
          </div>
        </a-form-model>
      </div>
    </a-card>
  </div>
</template>

<script>
import {checkPermission} from "@/utils/permissions";

export default {
  name: '',
  data() {
    return {
      handle: 'detail',
      detail: {},
      positionList: [],
      formRule: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        positionId: [{ required: true, message: '请选择职位', trigger: 'change' }],
        mobile: [{ required: true, pattern: new RegExp(/^[0-9]{1,18}$/), message: '请输入联系电话', trigger: 'blur' }],
        email: [
          {
            required: true,
            pattern: new RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/),
            message: '请输入正确的邮箱地址',
            trigger: 'blur',
          },
        ],
      },
      isEdit: checkPermission('business:card:edit'),
    }
  },

  computed: {},
  created() {
    this.getDetail()
  },
  methods: {
    // 获取名片详情
    getDetail() {
      this.axios.get(`/api/base/system/dealer/manager/businessCard/get`).then((res) => {
        if (res.code == 200) {
          this.detail = res.body
        }
      })
      // this.axios.get(`/api/dealer/dealer/dealerPosition/list?pageSize=9999`).then((res) => {
      //   if (res.code == 200) {
      //     this.positionList = res.body.records
      //   }
      // })
    },

    // 打开修改框
    onEdit() {
      this.handle = 'edit'
    },

    // 返回
    onHome() {
      this.handle = 'detail'
    },

    // 提交数据
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.axios.post(`/api/base/system/dealer/manager/businessCard/edit`, this.detail).then((res) => {
          if (res.code == 200) {
            this.handle = 'detail'
            this.$message.success(res.message)
          }
        })
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.ant-form-item {
  margin-bottom: 10px;
}
.footer-btns {
  text-align: right;
  margin-top: 20px;
}
</style>
